<template>
  <div class="school-data-student">
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold mb-6"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <MainCard
      v-if="!isLoadingTable"
    >
      <template v-slot:body>
        <DataTablePagination
          title="Data Siswa"
          subtitle="Halaman untuk melihat total seluruh siswa"
          :total-items="totalItems"
          :headers="headers"
          :items="students"
          :search="search"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :no-add-button="true"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @show-detail="showDetailStudent($event)"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="800"
      header="DATA LENGKAP SISWA"
      :hidden-actions="false"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
      >
        <v-tabs
          v-model="tab"
          show-arrows
          fixed-tabs
        >
          <v-tab
            v-for="(tabItem, index) in tabs"
            :key="tabItem.title"
          >
            <span>{{ tabItem.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="pt-6 pb-0"
        >
          <v-tab-item :eager="true">
            <student-data-personal
              ref="studentDataPersonal"
              @close-dialog="modalDialog = false"
            ></student-data-personal>
          </v-tab-item>
          <v-tab-item
            :eager="
              true"
          >
            <student-data-school
              ref="studentDataSchool"
              @close-dialog="addFormDialog = false"
            ></student-data-school>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import StudentDataPersonal from './StudentDataPersonal.vue'
import StudentDataSchool from './StudentDataSchool.vue'

export default {
  name: 'SchoolDataStudent',
  components: {
    DataTablePagination,
    SkeletonLoaderTable,
    ModalDialog,
    MainCard,
    StudentDataPersonal,
    StudentDataSchool,
  },
  data() {
    return {
      students: [],
      student: {},
      icons: {
        mdiArrowLeft,
      },
      search: '',
      page: 1,
      totalItems: 0,
      totalPages: 0,
      componentKey: 0,
      componentKey1: 0,
      isLoadingData: false,
      isLoadingTable: true,
      modalDialog: false,
      service: 'student',
      headers: [
        { text: '#', value: 'index' },
        { text: 'NIS', value: 'nis' },
        { text: 'NAMA LENGKAP', value: 'name' },
        { text: 'USERNAME', value: 'username' },
        { text: 'JENIS KELAMIN', value: 'gender' },
        { text: 'ALAMAT', value: 'place.address' },
        { text: 'FOTO', value: '' },
        { text: '', value: 'detail-student' },
      ],
      tab: '',
      tabs: [{ title: 'Data Pribadi' }, { title: 'Data Sekolah' }],
      dataPerson: {},
    }
  },
  watch: {
    page: {
      handler() {
        this.getStudent()
      },
    },
    search: {
      handler() {
        this.getStudent()
      },
    },
  },
  beforeMount() {
    this.getSchoolUuid()
  },
  mounted() {
    this.getStudent()
  },
  methods: {
    async getStudent(params = {}) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
        search: this.search,
      }).then(({ data }) => {
        this.students = data.data.map((student, index) => ({
          index: index + 1,
          ...student,
        }))
        this.isLoadingTable = false

        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
    },
    getSchoolUuid() {
      this.school_uuid = this.$route.params.uuid
    },
    changeData() {
      this.dataPerson = this.$refs.personData.dataPersonals
    },
    showDetailStudent(uuid) {
      this.$refs.studentDataPersonal.get(uuid)
      this.$refs.studentDataSchool.get(uuid)
      this.modalDialog = true
    },

    searchHandler(data) {
      this.search = data
    },
    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>

<template>
  <div>
    <v-simple-table>
      <tbody>
        <tr
          v-for="data,index in datas"
          :key="index"
        >
          <td>
            {{ data.title }}
          </td>
          <td>
            <span class="me-4">:</span> {{ data.content }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'StudentDataSchool',
  data() {
    return {
      datas: [
        { title: 'Tanggal Daftar Sekolah', keyName: 'entry_date', content: 'entry_date' },
        { title: 'Kendaraan', keyName: 'transport', content: 'transport' },
        { title: 'Jarak Rumah', keyName: 'home_distance', content: 'home_distance' },
        { title: 'Pindahan', keyName: 'is_transfer', content: 'is_transfer' },
      ],
    }
  },
  methods: {
    async get(uuid) {
      await this.$services.ApiServices.get('student', uuid).then(({ data }) => {
        Object.keys(data.data).forEach((key, el) => {
          this.datas.forEach(el => {
            if (el.keyName === key) {
              el.content = data.data[key]
            }
          })
        })
      })
    },
  },
}
</script>

<style>
</style>
